import React from "react"

const Video = ({ children }) => {
  return (
    <div>
      { children}
    </div>
  )
}

export default Video
